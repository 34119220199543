import {
  combineReducers,
  createStore,
} from 'redux';

// action types
const READY = 'READY';
const APPLICATIONDESCRIPTIONS = 'APPLICATIONDESCRIPTIONS';
const SETVIEW = 'SETVIEW';
const DATA = 'DATA';
const EXPANDAPPLICATIONS = "EXPANDAPPLICATIONS";

// helpers

const expandHelper = (state, action) => {
	if ( ( state + ( 2 * action.expandCount ) ) > action.maxIndex ) {
		return action.maxIndex;
	} else {
	 return state + action.expandCount;
	}
};


// actions.js

export const setReady = ready => ({
 type: READY,
 ready: ready,
});

export const setApplicationDescriptions = (
	applicationDescriptionWidth,
	applicationDescriptions
) => ({
 type: APPLICATIONDESCRIPTIONS,
 applicationDescriptionWidth: applicationDescriptionWidth,
 applicationDescriptions: applicationDescriptions,
});

export const setView = (view, applications) => ({
 type: SETVIEW,
 view: view,
 applications: applications,
});

export const setData = data => ({
 type: DATA,
 data: data,
});

export const expand = (expandble, expandCount, maxIndex, newItems=[] ) => ({
 type: expandble,
 expandCount: expandCount,
 maxIndex: maxIndex,
 newItems: newItems,
});


// reducers.js

export const ready = (state = 0, action) => {
 switch (action.type) {
   case READY:
     return action.ready;
   default:
     return state;
 }
};

export const applicationDescriptions = (state ={}, action) => {
 switch (action.type) {
	 	case SETVIEW:
	 			return {};
   case APPLICATIONDESCRIPTIONS:
     return JSON.parse(
     	JSON.stringify(
     		Object.assign(
     			{},
     			state,
     			{ [ action.applicationDescriptions ]:
	     			state[ action.applicationDescriptions ] ? false : true
	     		}
	     	)
	     )
	    );
   default:
     return state;
 }
};

export const applicationDescriptionWidth = (state = 0, action) => {
 switch (action.type) {
   case APPLICATIONDESCRIPTIONS:
     return action.applicationDescriptionWidth;
   default:
     return state;
 }
};

export const view = (state = "", action) => {
 switch (action.type) {
   case SETVIEW:
     return action.view;
   default:
     return state;
 }
};

export const data = (state = {}, action) => {
 switch (action.type) {
   case DATA:
     return action.data;
   default:
     return state;
 }
};

export const expApplications = (state = 0, action) => {
 switch (action.type) {
   case EXPANDAPPLICATIONS:
   		return expandHelper(state, action);
   default:
     return state;
 }
};


export const reducers = combineReducers({
  ready,
  applicationDescriptions,
  applicationDescriptionWidth,
  view,
  data,
  expApplications,
});


// store.js
export function configureStore(initialState = {
	ready: 0,
	view: "",
	data: {
		"header": {},
		"teams": [],
		"applications": []
	},
	expApplications: 6
}) {
 const store = createStore(
   reducers,
   initialState,
   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
 );
 return store;
};

export const store = configureStore();

import React from 'react';
import Responsive from 'react-responsive';

import { connect } from 'react-redux';

import {
	setReady,
	setApplicationDescriptions,
	setView,
	setData,
	expand,
} from './redux';

import './App.css';

const Desktop = props => <Responsive minWidth={632} {
	...props
} />;
const Mobile = props => <Responsive maxWidth={631} {
	...props
} />;

const toExpandApplications = 6;

// App.js
export class App extends React.Component {

	render() {
		return (
			<div>
				<Desktop><Resume
					viewName="Desktop"
					applicationTable="full"
					technologyTable="full"
					spacerColumn="&nbsp;&nbsp;&nbsp;&nbsp;"
					{ ...this.props }
				/></Desktop>
				<Mobile><Resume
					viewName="Mobile"
					applicationTable="slim"
					technologyTable="slim"
					spacerColumn="&nbsp;&nbsp;"
					{ ...this.props }
				/></Mobile>
			</div>
		);
	}

	componentDidMount() {
		fetch('js/data.json', {
			method: 'get',
			cache: 'no-cache',
			headers: {
				'Accept': 'application/json'
			}
		})
		.then(response => response.json()
			.then( response => {
				Object.keys( response.data ).map( (el) => {
					this.props.setData(
						Object.assign( {}, this.props.data, {[el]: response.data[el]} )
					);
					return null;
				} )
				this.props.setReady( 1 );
				return null;
			} )
		)
		.catch(err => console.error(err));
	}

}

export class Resume extends React.Component {

	render() {
	  return (
	    <div className="App" style={{
		   	display: this.props.ready===1?"block":"none"
		   }}>
			 <div style={{
				 marginBottom: "10px",
				 filter: "drop-shadow( 3px 9px 6px rgba( 255, 255, 255, 0.03 ) )"
			 }} >
			 <div className="headerPanelBackground" style={{
				 backgroundColor: "var(--header-1)"
			 }} >
				 <div className="headerPanelUnder" style={{
					 backgroundColor: "var(--header-2)"
				 }} >
				 <div style={{
					 filter: "drop-shadow( 1px 1px 1px rgba( 50, 50, 50, 0.5 ) )"
				 }} >
		      <div className="header" style={{
							color: "var(--header-4)",
					   	display:
								Object.keys(this.props.data.header).length===0
							?
								"none"
							:
								"block"
					   }} >
									<h1>{ this.props.data.header.name }</h1>
									<h2>{ this.props.data.header.title }</h2>
									{ this.props.technologyTable==="full"? (
											<h3>
												<a style={{
													color: "var(--header-4)"
												}} href={ 'mailto:' + this.props.data.header.email }>
													{ this.props.data.header.email }
												</a>
											 { ' \u22C5 ' }
												<a style={{
													color: "var(--header-4)"
												}} href={ this.props.data.header.website }>
													{ this.props.data.header.website }
												</a>
											</h3>
										) : (
											<div>
												<h3>
													<a style={{
														color: "var(--header-4)"
													}} href={ 'mailto:' + this.props.data.header.email }>
														{ this.props.data.header.email }
													</a>
												</h3>
												<h3>
													<a style={{
														color: "var(--header-4)"
													}} href={ this.props.data.header.website }>
														{ this.props.data.header.website }
													</a>
												</h3>
											</div>
										)
									}
		      				<div className="section centeredSection" style={{
			      				maxWidth: "1000px"
			      			}}>
										<p style={{
											fontSize: "normal"
										}}>{ this.props.data.header.purpose }</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
	      <div className="section centeredSection" style={{
				   	display: this.props.data.applications.length===0?"none":"block"
				   }}>
					 <div style={{
						 margin: "10px auto",
						 filter: "drop-shadow( 3px 9px 6px rgba( 50, 50, 50, 0.5 ) )"
					 }}>
						 <div className="panelBackground panelProject" style={{
							 maxWidth: "190px",
							 margin: "20px auto",
							 backgroundColor: "var(--section-1)",
							 padding: "4px"
						 }}>
							 <div className="panelUnder panelProject" style={{
								 backgroundColor: "var(--section-2)"
							 }}>
								 <div style={{
									 filter: "drop-shadow( 1px 1px 1px rgba( 50, 50, 50, 0.5 ) )"
								 }} >
									 <div className="panel panelProject" style={{
										 padding: "20px 0",
										 color: "var(--section-4)"
									 }}>
						      	<h2>My Teams</h2>
									</div>
								</div>
							</div>
						</div>
					</div>
	      	<div style={{
						display: "grid",
						gridGap: "30px 10px",
						gridTemplateColumns: "repeat( auto-fit, minmax(" +
							(
								this.props.viewName==="Mobile"
							?
								"300px"
							:
								"500px"
							) +
						", 1fr) )"
					}}>
			      { this.props.data.teams.map( ( team, indexT ) => {
							return (
								<div key={ indexT } style={{
									margin: "10px auto",
									filter: "drop-shadow( 3px 9px 6px rgba( 50, 50, 50, 0.5 ) )"
								}}>
									<div className="panelBackground panelProject" style={{
										margin: "10px",
										maxWidth: "500px",
										backgroundColor: "var(--item-1)",
										padding: "4px"
									}}>
										<div className="panelUnder panelProject" style={{
											backgroundColor: "var(--item-2)"
										}}>
											<div>
												<div className="panel panelProject" style={{
													padding: "25px",
													paddingTop: "30px",
													height: "100%",
													backgroundColor: "var(--item-3)"
												}}>
													<div style={{
														textAlign: "center",
														height: "4em"
													}}>
														<h3 style={{
															fontWeight: "bold",
															padding: "0"
														}}
														dangerouslySetInnerHTML={{__html: team.name.replace("\n","<br />")}}
														></h3>
													</div>
													<div style={{
														paddingTop: "var(--team-item-spacing)"
													}} >
														<span colSpan="2" style={{
															textAlign: "center",
															verticalAlign: "middle"
														}}>
															<div style={{
																padding: "20px 5px",
																wordWrap: "break-word",
																display: "block",
																border: "none",
																whiteSpace: "pre-line",
																textAlign: "left"
															}}>
																<div style={{
																	paddingBottom: "10px"
																}}>
																	{ team.description }
																</div>
																<div>
																	{ team.role }
																</div>
																<table style={{
																	width: "100%",
																	maxWidth: "400px",
																	fontSize: "0.8em"
																}}>
																	<thead>
																		<tr>
																			<th>Position</th>
																			<th style={{
																				textAlign: "left"
																			}}>Dates</th>
																		</tr>
																	</thead>
														      { team.positions.map(
																		( position, indexP ) => {
																			return (
																				<tbody key={ indexP }>
																					<tr>
																						<td>{ position.name }</td>
																						<td style={{
																							textAlign: "left"
																						}}>
																							{
																								position.from
																							} - {
																								position.to
																							}
																						</td>
																					</tr>
																				</tbody>
																			)
																		}
																	) }
																</table>
																{ team.website ? (
																	<div style={{
																		margin: "20px auto 0 auto",
																		filter:
																			"drop-shadow( 2px 6px 4px " +
																			"rgba( 50, 50, 50, 0.5 ) )",
																		maxWidth: "150px"
																	}}>
																		<div
																			className=
																				"panelDemoBackground panelDetail"
																			style={{
																				padding: "3px",
																				backgroundColor: "var(--action-1)"
																			}}
																		>
																			<div style={{
																				filter:
																					"drop-shadow( 1px 1px 1px " +
																					"rgba( 50, 50, 50, 0.5 ) )"
																			}}>
																				<div
																					className="panelDemoUnder panelDetail"
																					style={{
																						backgroundColor: "var(--action-2)"
																					}}
																				>
																					<div style={{
																						filter:
																							"drop-shadow( 1px 1px 1px " +
																							"rgba( 50, 50, 50, 0.5 ) )"
																					}}>
																						<div
																							className="panelDemo panelDetail"
																							style={{
																								padding: "10px 5px",
																								backgroundColor:
																									"var(--action-3)",
																								color: "var(--action-4)",
																								fontWeight: "bold",
																								textAlign: "center",
																								cursor: "pointer"
																							}}
																							onClick={ () => {
																								window.open( team.website );
																							} }
																						>
																							Team Website
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																):(null) }
																{ team.relatedLink ? (
																	<div style={{
																		margin: "20px auto 0 auto",
																		filter:
																			"drop-shadow( 2px 6px 4px " +
																			"rgba( 50, 50, 50, 0.5 ) )",
																		maxWidth: "150px"
																	}}>
																		<div
																			className=
																				"panelDemoBackground panelDetail"
																			style={{
																				padding: "3px",
																				backgroundColor: "var(--action-1)"
																			}}
																		>
																			<div style={{
																				filter:
																					"drop-shadow( 1px 1px 1px " +
																					"rgba( 50, 50, 50, 0.5 ) )"
																			}}>
																				<div
																					className="panelDemoUnder panelDetail"
																					style={{
																						backgroundColor: "var(--action-2)"
																					}}
																				>
																					<div style={{
																						filter:
																							"drop-shadow( 1px 1px 1px " +
																							"rgba( 50, 50, 50, 0.5 ) )"
																					}}>
																					<div
																						className="panelDemo panelDetail"
																						style={{
																							padding: "10px 5px",
																							backgroundColor:
																								"var(--action-3)",
																							color: "var(--action-4)",
																							fontWeight: "bold",
																							textAlign: "center",
																							cursor: "pointer"
																						}}
																						onClick={ () => {
																							window.open(
																								team.relatedLink.url
																							);
																						} }
																					>
																							{ team.relatedLink.name }
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																):(null) }
															</div>
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							)
						} ) }
					</div>
					<div style={{
					margin: "0px auto 30px auto",
					filter: "drop-shadow( 3px 9px 6px rgba( 50, 50, 50, 0.5 ) )"
					}}>
						<div className="panelBackground panelProject" style={{
						maxWidth: "190px",
						margin: "20px auto",
						backgroundColor: "var(--section-1)",
						padding: "4px"
						}}>
							<div className="panelUnder panelProject" style={{
							backgroundColor: "var(--section-2)"
							}}>
								<div style={{
								filter: "drop-shadow( 1px 1px 1px rgba( 50, 50, 50, 0.5 ) )"
								}} >
									<div className="panel panelProject" style={{
									 padding: "20px 0",
									 backgroundColor: "var(--section-3)",
									 color: "var(--section-4)"
									}}>
						      	<h2>My Projects</h2>
									</div>
								</div>
							</div>
						</div>
					</div>
	      	<div style={{
						display: "grid",
						gridGap: "30px 10px",
						gridTemplateColumns: "repeat( auto-fit, minmax(" +
							(
								this.props.viewName==="Mobile"
							?
								"300px"
							:
								"500px"
							) +
						", 1fr) )"
					}}>
			      { this.props.data.applications.map( ( application, indexA ) => {
							if ( indexA >= this.props.expApplications ) return (null);
							return (
								<div key={ indexA } style={{
									margin: "10px auto",
									filter: "drop-shadow( 3px 9px 6px rgba( 50, 50, 50, 0.5 ) )"
								}}>
									<div className="panelBackground panelProject" style={{
										maxWidth: "500px",
										backgroundColor: "var(--item-1)",
										padding: "4px"
									}}>
										<div className="panelUnder panelProject" style={{
											backgroundColor: "var(--item-2)"
										}}>
											<div>
												<div className="panel panelProject" style={{
													padding: "25px",
													paddingTop: "30px",
													height: "100%",
													backgroundColor: "var(--item-3)"
												}}>
													<div style={{
														textAlign: "center",
														height: "3em"
													}}>
														<h3 style={{
															fontWeight: "bold"
														}}>{
															application.name
														}</h3>
													</div>
													<div style={{
														paddingTop: "var(--item-spacing)"
													}} >
														<span colSpan="2" style={{
															textAlign: "center",
															verticalAlign: "middle"
														}}>
															<div style={{
																margin: "10px auto",
																filter:
																	"drop-shadow( 2px 6px 4px " +
																	"rgba( 50, 50, 50, 0.5 ) )"
															}}>
																<div className="panelUnder" style={{
																	backgroundColor: "var(--detail-1)"
																}}>
																	<div>
																		<div className="panel" style={{
																			padding: "30px 10px"
																		}}>
																			{ ( application.team ) ? (
																			 	<div style={{
																				 textAlign: "center",
																				 fontWeight: "bold",
																				 padding: "0 0 10px 0"
																				}}>
																					{ application.team } Team
																				</div>
																			) : (null) }
																			<div style={{
																				textAlign: "center",
																				fontWeight: "bold",
																				padding: "0 0 10px 0"
																			 }}>
																				{
																					application.start
																				} - {
																					application.until==="Present"
																				?
																					(<strong>Present</strong>)
																				:
																					application.until
																				}
																			</div>
																			<hr style={{
																				display:
																					application.logos.length>0
																				?
																					"block"
																				:
																					"none",
																				width: "100%",
																				margin: "5px 0"
																			}} />
																			<div style={{
																				textAlign: "center",
																				fontWeight: "bold",
																				padding: "10px 0"
																			 }}>
																				{ application.architecture }
																			</div>
																			<hr style={{
																				display:
																					application.logos.length>0
																				?
																					"block"
																				:
																					"none",
																				width: "100%",
																				margin: "5px 0"
																			}} />
																			<div style={{
																				display: "grid",
																				gridGap: "3px",
																				gridTemplateColumns:
																					"repeat( " +
																						"auto-fit, minmax(60px, 1fr) " +
																					")"
																			}}>
																				{ application.logos.map(
																					( logo, indexL ) => {
																						return (
																							<div key={ indexL } style={{
																								textAlign: "center",
																								filter:
																									"drop-shadow( 2px 6px 4px " +
																									"rgba( 50, 50, 50, 0.5 ) )"
																							}}>
																								<img
																									src={ 'images/' + logo }
																									style={{
																										height: "70px"
																									}}
																									alt={ logo }
																								/>
																							</div>
																						);
																					}
																				) }
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div style={{
																padding: "20px 5px",
																wordWrap: "break-word",
																display: "block",
																border: "none",
																whiteSpace: "pre-line",
																textAlign: "left"
															}}>
																{ application.description }
															</div>
															{ application.demoURL && (
																this.props.viewName==="Desktop"
															||
																application.demoURL.mobile
															) ? (
																<div style={{
																	margin: "10px auto",
																	filter:
																		"drop-shadow( 2px 6px 4px " +
																		"rgba( 50, 50, 50, 0.5 ) )",
																	maxWidth: "80px"
																}}>
																	<div
																		className="panelDemoBackground panelDetail"
																		style={{
																			padding: "3px",
																			backgroundColor: "var(--action-1)"
																		}}
																	>
																		<div style={{
																			filter:
																				"drop-shadow( 1px 1px 1px " +
																				"rgba( 50, 50, 50, 0.5 ) )"
																		}}>
																			<div
																				className="panelDemoUnder panelDetail"
																				style={{
																					backgroundColor: "var(--action-2)"
																				}}
																			>
																				<div style={{
																					filter:
																						"drop-shadow( 1px 1px 1px " +
																						"rgba( 50, 50, 50, 0.5 ) )"
																				}}>
																					<div
																						className="panelDemo panelDetail"
																						style={{
																							padding: "10px 5px",
																							backgroundColor:
																								"var(--action-3)",
																							color: "var(--action-4)",
																							fontWeight: "bold",
																							textAlign: "center",
																							cursor: "pointer"
																						}} onClick={ () => {
																							window.open(
																								application.demoURL.url
																							);
																						} }
																					>
																						{ application.demoURL.text }
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															):(null) }
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							)
						} ) }
					</div>
					{ (
						this.props.data.applications.length > this.props.expApplications
					) ? (
						<div style={{
							padding: "50px 0 0 0"
						}}>
							<div style={{
								margin: "10px auto",
								filter: "drop-shadow( 2px 6px 4px rgba( 50, 50, 50, 0.5 ) )",
								maxWidth: "100px"
							}}>
								<div className="panelDemoBackground panelDetail" style={{
									padding: "3px",
									backgroundColor: "var(--seemore-1)"
								}}>
									<div style={{
										filter: "drop-shadow( 1px 1px 1px rgba( 50, 50, 50, 0.5 ) )"
									}}>
										<div className="panelDemoUnder panelDetail" style={{
											backgroundColor: "var(--seemore-2)"
										}}>
											<div style={{
												filter:
													"drop-shadow( 1px 1px 1px rgba( 50, 50, 50, 0.5 ) )"
											}}>
												<div className="panelDemo panelDetail" style={{
													padding: "10px 5px",
													backgroundColor: "var(--seemore-3)",
													color: "var(--seemore-4)",
													fontWeight: "bold",
													textAlign: "center",
													cursor: "pointer"
												}} onClick={ () => {
													this.props.expand(
														"EXPANDAPPLICATIONS",
														toExpandApplications,
														this.props.data.applications.length,
														this.props.data.applications.slice(
															this.props.expApplications,
															this.props.expApplications + toExpandApplications
														)
													);
												} } >
													See more
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					):(null) }
				</div>
	    </div>
	  );
	}

	componentDidMount() {
		this.props.setView(
			this.props.viewName,
			this.props.data.applications.slice(
				0,
				this.props.viewName==="Mobile"?
					this.props.expApplications
				:
					this.props.data.applications.length
			)
		);
	}

}

// AppContainer.js
const mapStateToProps = (state, ownProps) => (
	Object.assign({},state,ownProps)
);

const mapDispatchToProps = {
	setReady,
	setApplicationDescriptions,
	setView,
	setData,
	expand,
};

const AppContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

export default AppContainer;
